<template>
  <div>
    <div class="dashboard-content">
      <div class="type-title">
        <div style="width: 300px;margin:0 auto; border-bottom: #e4e4e4 1px;">
          <b>创建会员</b>
        </div>
      </div>
    </div>
    <div style="width: 600px">
      <el-form ref="form" label-width="150px" style="padding: 30px">
        <el-form-item size="small" label="姓名:" required>
          <el-input v-model="memberName" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item size="small" label="手机号:" required>
          <el-input v-model="phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item size="small" label="性别:" required>
          <el-radio-group v-model="sex">
            <el-radio :label="'0'">男</el-radio>
            <el-radio :label="'1'">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item size="small" label="生日:" required>
          <el-date-picker
            v-model="birthday"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item size="small" label="客户来源:" required>
          <el-radio-group v-model="customersSource">
            <el-radio style="width: 80px;margin-bottom: 10px" :label="'0'">上门客人</el-radio>
            <el-radio style="width: 80px" :label="'1'">员工带客</el-radio>
            <el-radio style="width: 80px" :label="'2'">客带客</el-radio>
            <br>
            <el-radio style="width: 80px;margin-bottom: 10px" :label="'3'">大众点评</el-radio>
            <el-radio style="width: 80px" :label="'4'">美团</el-radio>
            <el-radio style="width: 80px" :label="'5'">支付宝</el-radio>
            <br>
            <el-radio style="width: 80px" :label="'6'">商家</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item size="small" label="备注:">
          <el-input
            type="textarea"
            :rows="7"
            placeholder="请输入内容"
            v-model="remarks">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="type-title">
      <div style="width: 300px;margin:0 auto; border-bottom: #e4e4e4 1px;">
        <div style="margin-bottom: 30px;margin-top: 30px;">
          <el-button size="small" @click="closeEvent">取 消</el-button>
          <el-button size="small" type="primary" @click="saveMember">确 定</el-button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import {Base_url} from '../../../config/index'
  import {getToken} from '../../../utils/auth'

  export default {
    data() {
      return {
        memberName: '',
        phone: '',
        sex: '0',
        birthday: '',
        customersSource: '0',
        remarks: '',


        options: [
          {
            value: '0',
            name: '卖品',
          }, {
            value: '1',
            name: '非卖品',
          }
        ],

      }
    },

    created() {
      this.Base_url = Base_url
    },
    methods: {

      closeEvent() {
        this.$router.push('/customers/customer')
      },

      //图片相关
      imageSuccess(res) {
        this.skuImgUrl = res.fileUrl
      },
      // 上传文件之前
      beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 3;

        if (!isJPG && !isPNG) {
          this.$message.error('请上传JPG或PNG格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传大小不能超过 3MB!');
        }
        return (isJPG || isPNG) && isLt2M;
      },

      async saveMember() {
        if (!this.memberName) {
          this.$message.error('请输入姓名');
          return
        }
        if (!this.phone) {
          this.$message.error('请输入手机号');
          return
        }
        // if (!this.birthday) {
        //   this.$message.error('请选择生日');
        //   return
        // }

        let data = {
          token: getToken(),
          memberName: this.memberName,
          phone: this.phone,
          sex: this.sex,
          birthdayStr: this.birthday,
          customersSource: this.customersSource,
          remarks: this.remarks,
        };

        let res = await this.$post("/api/save_mbMember", data)
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$router.push('/customers/customer')
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      }
    }
  }
</script>

<style>
  .dashboard-content {
    padding: 0 15px;
    background: #fff;
    border-bottom: #e4e4e4 solid 1px;
  }

  .type-title {
    display: flex;
    justify-content: space-between;
    line-height: 50px;
    padding-left: 20px;
    text-align: center;

  }

  .type-title b {
    padding: 0px 30px;
    color: #212540;
    font-size: 25px;
  }
</style>
